import { type CalculateAmountOptions, pumpSdk } from '@libs/pump';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

export const usePumpCalculateTokenAndThresholdAmount = (options: CalculateAmountOptions) => {
  const [receive, setReceive] = useState<
    { tokenAmount: string; thresholdAmount: string } | undefined
  >();

  useDeepCompareEffect(() => {
    const calculateTokenAndThresholdAmount = async () => {
      if (!options.amount || !options.coinType) {
        setReceive(undefined);
        return;
      }
      const result = await pumpSdk.calculateTokenAndThresholdAmount(options);
      setReceive(result);
    };
    calculateTokenAndThresholdAmount();
  }, [options]);

  return receive;
};
