import { type PoolFields, pumpSdk } from '@libs/pump';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './index.module.scss';
import kingIcon from '@assets/images/pump/king.svg';
import dayjs from 'dayjs';

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
  poolFields: PoolFields;
}

const Process: FC<OwnerProps> = ({ pool, poolFields }) => {
  const availableRatio = pumpSdk.getTokenRatioForAmount(poolFields.real_token_reserves);
  let ratio = new Decimal(poolFields.real_sui_reserves)
    .div(pumpSdk.fair_launch_sui_amount)
    .mul(100)
    .toFixed(2);
  ratio = Number(ratio) > 100 ? '100' : ratio;

  let kingRatio = new Decimal(ratio).div(0.8).toFixed(2);
  kingRatio = Number(kingRatio) > 100 ? '100' : kingRatio;

  const timezoneName = dayjs.tz.guess();
  const offsetHours = dayjs(pool.king_at).tz(timezoneName).utcOffset() / 60;

  return (
    <div className={styles.wrapper}>
      <div className={styles.process}>Bonding Curve Progress: {ratio}%</div>
      <div className={styles.percent}>
        <div className={styles.width} style={{ width: `${ratio}%` }}></div>
      </div>
      <div className={styles.text}>
        <p>
          There are{' '}
          <span>
            {bigNumberToReadable(
              new Decimal(poolFields.real_token_reserves)
                .div(10 ** pool.token_metadata.decimals)
                .toString(),
              0,
              true,
            )}{' '}
            ({bigNumberToReadable(availableRatio, 0, false, 2)}%) ${pool.symbol}{' '}
          </span>
          still available for sale in the bonding curve and there are{' '}
          <span>
            {bigNumberToReadable(
              new Decimal(poolFields.real_sui_reserves)
                .div(10 ** pumpSdk.quote_decimals)
                .toString(),
              0,
              true,
              2,
            )}{' '}
            $SUI
          </span>{' '}
          in the bonding curve.
        </p>
        <p>
          When the market cap reaches{' '}
          <span>
            {bigNumberToReadable(
              new Decimal(pumpSdk.fair_launch_sui_amount)
                .div(10 ** pumpSdk.quote_decimals)
                .toString(),
              0,
              true,
            )}{' '}
            <em>{bigNumberToReadable(6000, 0, true)}</em> $SUI
          </span>
          , all the liquidity from the bonding curve will be deposited onto Turbos DEX and locked.
          Progress increases as the price goes up.
        </p>
      </div>
      {pool.king_at ? (
        <div className={styles.over}>
          <img src={kingIcon} />
          <div className={styles.over_time}>
            <div className={styles.text1}>King of Racing</div>
            <div className={styles.text2}>
              on {dayjs(pool.king_at).tz(timezoneName).format('YYYY/MM/DD HH:mm:ss')} (UTC
              {offsetHours > 0 ? `+${offsetHours}` : offsetHours})
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.king}>King of Racing: {kingRatio}%</div>
          <div className={styles.percent1}>
            <div className={styles.width} style={{ width: `${kingRatio}%` }}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Process;
